<template>
  <div>
    <div class="d-flex justify-content-between">
      <!-- LEFT SIDE -->
      <div class="col-12 col-lg-6 px-4">
        <h2 class="highlighted-color1 mb-4">
          <span>Meu Perfil</span>
        </h2>
        <form @submit.prevent="submit" class="default-form">
          <div class="form-group mb-3">
            <label class="form-label fw-bold">Nome completo</label>
            <input type="text" class="form-control" placeholder="Nome completo" v-model.trim="$v.form.name.$model" />
          </div>
          <div class="form-group mb-3">
            <label class="form-label fw-bold">CPF</label>
            <the-mask class="form-control" placeholder="000.000.000-00" mask="###.###.###-##" v-model.trim="$v.form.cpf.$model" />
          </div>
          <div class="form-group mb-3">
            <label class="form-label fw-bold">E-mail</label>
            <input type="email" class="form-control" placeholder="email@email.com.br" v-model.trim="$v.form.email.$model" />
          </div>
          <div class="form-group mb-3">
            <label class="form-label fw-bold">Celular</label>
            <the-mask class="form-control" placeholder="(00) 00000-0000" mask="(##) #####-####" v-model.trim="$v.form.phone.$model" />
          </div>
          <div class="text-center mt-3">
            <Button
              type="submit"
              class="btn btn-primary"
              :disabled="$v.$invalid"
              :loading="loader === 'settings'"
            >
              Salvar
            </Button>
          </div>
        </form>
      </div> <!-- END LEFT SIDE -->
      <!-- RIGHT SIDE -->
      <div class="col-12 col-lg-6 ps-4">
        <div class="mb-4">
          <h2 class="highlighted-color1 mb-4">
            <span>Resetar senha</span>
          </h2>
           <div class="d-flex justify-content-between align-items-center">
             <div class="col-12 col-lg-9">
               <p class="mb-0">Você receberá um e-mail com o link para troca.</p>
             </div>
             <div>
              <Button
                type="button"
                class="btn btn-primary btn-sm text-nowrap"
                @click="sendResetPasswordLink"
                :loading="loader === 'password'"
              >
                Trocar senha
              </Button>
             </div>
           </div>
        </div>
        <div v-if="user">
          <!-- 2FA -->
          <div class="mb-4">
            <h2 class="highlighted-color1 mb-4">
              <span>Autenticação em dois fatores</span>
            </h2>
            <div class="d-flex justify-content-between align-items-center">
              <div class="col-12 col-lg-9">
                <p class="mb-0">Com a autenticação em dois fatores, você recebe um código enviado por email para completar seu login, garantindo assim mais segurança.</p>
              </div>
              <div>
                <Button
                  type="button"
                  class="btn btn-sm"
                  :class="{
                    'btn-primary': !user.use_2fa,
                    'btn-danger': user.use_2fa
                  }"
                  @click="toggle2FA"
                  :loading="loader === '2fa'"
                >
                  {{ user.use_2fa ? 'Desativar' : 'Ativar' }}
                </Button>
              </div>
            </div>
          </div> <!-- END 2FA -->
          <!-- EMAILS ENABLED -->
          <div class="mb-3">
            <h2 class="highlighted-color1 mb-4">
              <span>Recebimento de e-mails</span>
            </h2>
            <div class="d-flex justify-content-between align-items-center">
              <div class="col-12 col-lg-9">
                <p class="mb-0">
                  Controle o recebimento de e-mails automáticos da Pixel Roads, como lembretes de verba, estatísticas de campanha e outros.<br/>
                  <small><strong>Importante:</strong> Esse filtro não se aplica a e-mails transacionais obrigatórios, como confirmação de pagamento, recuperação de senha e outros.</small>
                </p>
              </div>
              <div>
                <Button
                  type="button"
                  class="btn btn-sm"
                  :class="{
                    'btn-primary': !user.is_emails_enabled,
                    'btn-danger': user.is_emails_enabled
                  }"
                  @click="toggleEmailsEnabled"
                  :loading="loader === 'emails-enabled'"
                >
                  {{ user.is_emails_enabled ? 'Desativar' : 'Ativar' }}
                </Button>
              </div>
            </div>
          </div> <!-- END EMAILS ENABLED -->
          <!-- WHATSAPP ENABLED -->
          <div class="mb-3">
            <h2 class="highlighted-color1 mb-4">
              <span>Recebimento de WhatsApp</span>
            </h2>
            <div class="d-flex justify-content-between align-items-center">
              <div class="col-12 col-lg-9">
                <p class="mb-0">
                  Controle o recebimento de mensagens automáticas da Pixel Road via WhatsApp, como lembretes de verba, estatísticas de campanha e outros.<br/>
                  <small><strong>Importante:</strong> Esse filtro não se aplica a mensagens transacionais obrigatórias, como confirmação de pagamento e outros.</small>
                </p>
              </div>
              <div>
                <Button
                  type="button"
                  class="btn btn-sm"
                  :class="{
                    'btn-primary': !user.is_whatsapp_enabled,
                    'btn-danger': user.is_whatsapp_enabled
                  }"
                  @click="toggleWhatsappEnabled"
                  :loading="loader === 'whatsapp-enabled'"
                >
                  {{ user.is_whatsapp_enabled ? 'Desativar' : 'Ativar' }}
                </Button>
              </div>
            </div>
          </div> <!-- END WHATSAPP ENABLED -->
        </div>
      </div> <!-- END RIGHT SIDE -->
    </div>
  </div>
</template>

<script>

import AuthService from '@/modules/auth/services/auth-service'
import NotificationService from '@/modules/notifications/services/notifications-service'
import UsersService from '@/modules/users/services/users-service'
import { required, email } from 'vuelidate/lib/validators'
import { mapState } from 'vuex'

import Button from '@/components/common/Button/Button'
import { TheMask } from 'vue-the-mask'

export default {
  components: {
    Button,
    TheMask
  },
  data () {
    return {
      loader: '',
      form: {
        name: '',
        email: '',
        cpf: '',
        phone: ''
      }
    }
  },
  metaInfo: {
    title: 'Meu Perfil'
  },
  validations () {
    return {
      form: {
        name: { required },
        email: { required, email },
        cpf: { required },
        phone: { required }
      }
    }
  },
  created () {
    this.setUser()
  },
  watch: {
    user () {
      this.setUser()
    }
  },
  computed: {
    ...mapState('users', {
      user: state => state.user
    })
  },
  methods: {
    /**
     * Send password reset link
     */
    async sendResetPasswordLink () {
      this.loader = 'password'
      try {
        await AuthService.sendResetPasswordEmail(this.user.email)
        const notification = {
          notification: {
            type: 'success',
            content: 'Enviamos um email com as instruções para troca de senha'
          }
        }
        NotificationService.createGlobalSuccessNotification(notification)
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.loader = ''
    },
    /**
     * Set user data to form
     */
    setUser () {
      if (this.user) {
        this.form.name = this.user.name
        this.form.email = this.user.email
        this.form.cpf = this.user.document
        this.form.phone = this.user.phone
      }
    },
    /**
     * Update user
     */
    async submit () {
      this.loader = 'settings'
      try {
        await UsersService.updateUser(this.user.id, this.form)
        await UsersService.me()
        const notification = {
          notification: {
            type: 'success',
            content: 'Dados alterados com sucesso'
          }
        }
        NotificationService.createGlobalSuccessNotification(notification)
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.loader = ''
    },
    /**
     * Toggle 2FA use
     */
    async toggle2FA () {
      this.loader = '2fa'
      try {
        await UsersService.toggle2FA(this.user.id)
        await UsersService.me()
        const notification = {
          notification: {
            type: 'success',
            content: 'Autenticação em dois fatores alterada com sucesso'
          }
        }
        NotificationService.createGlobalSuccessNotification(notification)
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.loader = ''
    },
    /**
     * Toggle emails enabled
     */
    async toggleEmailsEnabled () {
      this.loader = 'emails-enabled'
      try {
        await UsersService.toggleEmailsEnabled(this.user.id)
        await UsersService.me()
        const notification = {
          notification: {
            type: 'success',
            content: 'Recebimento de e-mails alterado com sucesso'
          }
        }
        NotificationService.createGlobalSuccessNotification(notification)
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.loader = ''
    },
    /**
     * Toggle whatsapp enabled
     */
    async toggleWhatsappEnabled () {
      this.loader = 'whatsapp-enabled'
      try {
        await UsersService.toggleWhatsappEnabled(this.user.id)
        await UsersService.me()
        const notification = {
          notification: {
            type: 'success',
            content: 'Recebimento de mensagem de WhatsApp alterado com sucesso'
          }
        }
        NotificationService.createGlobalSuccessNotification(notification)
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.loader = ''
    }
  }
}
</script>
